import { PropsWithChildren, useCallback } from 'react';

import { RMAvatarSharing } from '@/components/RMAvatarSharing';
import { RMButton } from '@/components/RMButton/RMButton';
import { useBooleanQueryParam } from '@/hooks/useQueryParam';
import { ProjectShareDialogContainer } from '@/modules/sharing/containers/ProjectShareDialog.container';
import { useServices } from '@/Services';
import { CollaborationInviteSourcePage } from '@/services/analytics/collaboration-analytics';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import { useProjectMembers } from '../hooks/useProjectMembers';

export type ProjectShareHeaderContainerProps = PropsWithChildren<{
  projectId: string | null;
  page: CollaborationInviteSourcePage;
}>;

export function ProjectShareHeaderContainer({ projectId, page, children }: ProjectShareHeaderContainerProps) {
  const { collaborationAnalyticsService } = useServices();
  const members = useProjectMembers(projectId);

  const validMembers = members?.filter((member) => member.type !== 'invited' && member?.initials != null);
  const validMembersCount = validMembers?.length ?? 0;
  const maxMembers = 5;
  const membersShowLimit = validMembersCount > maxMembers ? maxMembers - 1 : maxMembers;
  const membersExceeded = validMembersCount - membersShowLimit;

  const [shareDialogOpen, setShareDialogOpen] = useBooleanQueryParam('invite-dialog', false);

  const handleInvite = useCallback(() => {
    setShareDialogOpen(true);
    collaborationAnalyticsService.onInviteDialogOpened(page);
  }, [collaborationAnalyticsService, page, setShareDialogOpen]);

  return (
    <>
      {projectId && (
        <ProjectShareDialogContainer
          projectId={projectId}
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(null)}
        />
      )}
      <RMAvatarSharing.Root>
        <>
          {validMembers
            ?.filter((_, i) => i < membersShowLimit)
            ?.map((member) => {
              if (member.type === 'invited' || member?.initials == null) {
                return null;
              }
              return (
                <RMAvatarSharing.Item
                  key={member.userId}
                  size="small"
                  border="surface"
                  imageURL={member.avatarUrl}
                  text={member.initials}
                  tooltip={member.name?.full}
                  backgroundColor={member.avatarColor}
                  overlapping={true}
                  onClick={handleInvite}
                />
              );
            })}
          {members && membersExceeded > 0 && (
            <RMAvatarSharing.Item
              size="small"
              border="surface"
              text={`${membersExceeded}+`}
              backgroundColor={ColorsTokens['on-secondary']}
              overlapping={true}
              onClick={handleInvite}
            />
          )}
        </>
      </RMAvatarSharing.Root>
      <RMButton id="page-header-invite-button" size="small" onClick={handleInvite}>
        Invite
      </RMButton>

      {children}
    </>
  );
}
